import React from "react";
import CbaLogo from "assets/img/brand/cba/justicia-logo.png";
import CbaLogoLight from "assets/img/brand/cba/justicia-logo-light.png";
import CbaFav from "assets/img/brand/cba/favicon-cordoba.png";
import DefaultCba from "assets/img/brand/cba/defaultCordoba.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'cba';

export const sources = {
    cba: {
        url: 'https://subastas.justiciacordoba.gob.ar',
        logo: CbaLogo,
        logolight: CbaLogoLight,
        ws_path: 'wss://subastas.justiciacordoba.gob.ar/wss',
        name: 'Subastas Judiciales Córdoba',
        navbar_class: 'navbar-cba',
        headroom_class: 'headroom-cba',
        favicon: CbaFav,
        theme_color: '#00857D',
        theme_name: 'cordoba',
        defaultImg: DefaultCba,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6LeRwX4UAAAAANQBooYiXOVtIEXO2OIaXs2MtGzI',
        gtm_ID: 'GTM-588CFB6',
        contact_data: {
            title: <><h5>Oficina de subastas</h5>
                    <h5>Poder Judicial de la Provincia de Córdoba</h5>
                </>,
            lines: [
                'Arturo M. Bas 158, X5000KLD Córdoba',
                'Teléfono: +54 (351) 4481000-4481600 int. 37301',
                'Email: subastas@justiciacordoba.gob.ar',
            ]
        },
        support_email: 'soporte@esubasta.ar'
    },
};